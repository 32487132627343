import {Typography} from "@mui/material";


function Header() {
  return (
    <div>
      <Typography variant="h4" component="h1" textAlign="left" p="0.6em 0">
        Curvas de ocupación y precios Ouigo
      </Typography>
      <Typography variant="h6" component="h2" textAlign="left" p="0.5em 0">
        Muestra una gráfica con la evolución del precio y la ocupación
      </Typography>
    </div>
  );
};

export default Header;