import logo from './logo.svg';
import './App.css';
import Header from "./Header";
import PriceSeat from "./PriceSeat";
import {Container} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <div className="App">
        <Container maxWidth="lg">
          <Header />
          <PriceSeat />
        </Container>
      </div>
    </LocalizationProvider>
  );
}

export default App;
