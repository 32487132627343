import * as React from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DatePicker from '@mui/lab/DatePicker';
import {useEffect} from "react";
import {format} from "date-fns";
import ReactECharts from 'echarts-for-react';

var gcs_endpoint = "https://bamaza-api.bamaza.workers.dev/seatinfo";
var gcs_endpoint_journeys = "https://bamaza-api.bamaza.workers.dev/journeys";

const generateEchartConfig = (title, timestamps, seats, prices) => {
  var seatsderivative = [0];
  for (var i = 0; i < seats.length-1; i++) {
    seatsderivative.push(seats[i+1] - seats[i]); 
  }
  var seatdata = timestamps.map((ts, i) => [ts, seats[i]]);
  var pricedata = timestamps.map((ts, i) => [ts, prices[i]]);
  var derivativedata = timestamps.map((ts, i) => [ts, seatsderivative[i]]);
  return {
    title: {
      text: title,
      left: "center",
      // textAlign: "center",
      textVerticalAlign: "top",
      // padding: 20,
    },
    legend: {
      data: ["Asientos", "Precio", "Aumento pasajeros"],
      orient: 'horizontal',
      top: 30,
      selected: {
        "Asientos": true,
        "Precio": true,
        "Aumento pasajeros": false,
      }
    },
    grid: {
      y: 100,
    },
    tooltip: {
      trigger: 'axis'
    },
    toolbox: {
      right: 0,
      feature: {
        // dataZoom: {
        //     yAxisIndex: 'none'
        // },
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        start: 0,
      },
      {
        type: 'inside',
      }
    ],
    xAxis: {
      type: "time",
      data: timestamps,
    },
    yAxis: [
      {
        type: "value",
        name: "Asientos",
        min: 0,
        max: Math.max(...seats),
        position: "left",
        minorSplitLine: {
          show: true
        }
      },
      {
        type: "value",
        name: "Precio",
        min: 0,
        max: Math.max(...prices),
        position: "right",
        axisLabel: {
          formatter: '{value} €'
        },
      },
    ],
    series: [
      {
        name: "Asientos",
        type: "line",
        data: seatdata,
      },
      {
        name: "Precio",
        type: "line",
        data: pricedata,
        yAxisIndex: 1,
      },
      {
        name: "Aumento pasajeros",
        type: "line",
        data: derivativedata,
      }
    ]
  }
}

const findJourneys = (userdate) => {
  return fetch(gcs_endpoint_journeys + "?date=" + userdate, {
    "method": "GET",
    "headers": {
      "Content-Type": "application/json"
    }
  }).then(response => response.json())
}

const findChartData = (service, departure, arrival, userdate) => {
  // echarts.init(document.getElementById('main')).showLoading();

  // set_status("Cargando...", true);

  let urlservice = new URL(gcs_endpoint);
  urlservice.searchParams.append("service", service);
  urlservice.searchParams.append("date", userdate);
  urlservice.searchParams.append("departure", departure);
  urlservice.searchParams.append("arrival", arrival);

  return fetch(urlservice, {
    "method": "GET",
    "headers": {
      "Content-Type": "application/json"
    }
  })
    .then(
      response => response.json()
    )
    .then(r => {
      // set_status("Procesando datos...", false);
      console.log(r.data);
      var objseats = r.data.sold_all;
      var objprices = r.data.price;

      var salidatimes = Object.values(r.data.time);
      let horasalida = salidatimes.pop();

      var timestamps = Object.keys(objseats).map(t => parseInt(t));
      var seats = Object.values(objseats);
      var prices = Object.values(objprices);

      var stats = r.stats;
      // generateChart(ctx, timestamps, seats, prices);
      // var title = "Tren " + service + " del día " + userdate + " a las " + horasalida;
      var title = userdate + " - " + service;
      // updateEchart(title, timestamps, seats, prices);
      // set_status("", false);
      return {title, timestamps, seats, prices, stats}
    })
    .catch(err => {
      console.error(err);
      // set_status("¡ERROR! Revisa la consola y las llamadas a las APIs", true);
    });
};

var deps = [
  // {value: "MAD", name: "MADRID"}
]

function PriceSeat() {
  const [day, setDay] = React.useState(new Date());

  const [services, setServices] = React.useState([]);
  const [serviceId, setServiceId] = React.useState("");
  const [service, setService] = React.useState({});
  const [loadingServices, setLoadingServices] = React.useState(false);

  const [departures, setDepartures] = React.useState(deps);
  const [departure, setDeparture] = React.useState(deps);
  const [arrivals, setArrivals] = React.useState(deps);
  const [arrival, setArrival] = React.useState(deps);

  const [chartOption, setChartOption] = React.useState({});
  const [chartLoading, setChartLoading] = React.useState(false);

  const [stats, setStats] = React.useState({});

  const [status, setStatus] = React.useState("");

  useEffect((e) => {
    setLoadingServices(true)
    setServices([])
    setService({})
    setServiceId("")
    setDepartures([])
    setArrivals([])
    setServiceId("")

    let dia = format(day, "yyyy-MM-dd")
    console.log("e", dia)
    findJourneys(dia)
      .then(r => {
        setServices(r.journeys);
        setLoadingServices(false)
      })
      .catch(err => {
        console.error(err);
        setStatus("¡ERROR cargando journeys! Revisa la consola y las llamadas a las APIs");
        setLoadingServices(false)
      });
  }, [day])


  const changedService = (eventServiceId) => {
    setServiceId(eventServiceId);
    console.log("service changed to ", eventServiceId)

    let serviceObj = null;
    for (let i = 0; i < services.length; i++) {
      if (services[i].service == eventServiceId) {
        serviceObj = services[i];
      }
    }
    setService(serviceObj);

    let stops = serviceObj.stops

    let departures_stations = stops.split("-").slice(0, -1)

    let arrivals_stations = stops.split("-").slice(1)
    // set departures and arrivals
    setDepartures(departures_stations.map((departure) => {
      return {value: departure, name: departure}
    }))
    setDeparture(departures_stations.at(0))

    setArrivals(arrivals_stations.map((arrival) => {
      return {value: arrival, name: arrival}
    }))
    setArrival(arrivals_stations.at(-1))
  }

  const show_chart = () => {
    setChartLoading(true);
    let dia = format(day, "yyyy-MM-dd")
    console.log("Chart para", serviceId, departure, arrival, dia)
    findChartData(serviceId, departure, arrival, dia).then(
      (respObj) => {
        console.log("yes", respObj.title, respObj.timestamps, respObj.seats, respObj.prices);
        let config = generateEchartConfig(respObj.title, respObj.timestamps, respObj.seats, respObj.prices);
        setChartOption(config);
        setChartLoading(false);
        setStats(respObj.stats);
      }
    )
  }

  return (
    <Grid container
      // spacing={2}
          style={{
            margin: "1em 0",
          }}>

      <Grid container
            spacing={2}
      >
        <Grid item xs={4} sm={3} md={3} style={{
          margin: "auto 0",
        }}>
          Seleccione una fecha:
        </Grid>
        <Grid item xs={8} sm={9} md={9}>
          <DatePicker
            label="Fecha de circulación"
            value={day}
            onChange={(v) => setDay(v)}
            renderInput={(params) =>
              <Box sx={{ position: 'relative' }}>
                <TextField fullWidth {...params}/>
                {loadingServices &&
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                }
              </Box>
            }
          />
        </Grid>
        {
          services.length > 0 ?
            <>
              <Grid item xs={4} sm={3} md={3} style={{
                margin: "auto 0",
              }}>
                Elija un servicio:
              </Grid>
              <Grid item xs={8} sm={3} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="label-select-service">Servicio</InputLabel>
                  <Select
                    labelId="label-select-service"
                    id="select-service"
                    value={serviceId}
                    label="Servicio"
                    onChange={(e) => changedService(e.target.value)}
                    // color={"secondary"}
                  >
                    {
                      services.map((service) => {
                        return <MenuItem key={service.service}
                                         value={service.service}>{service.service} - {service.departure_time} - {service.stops}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
            </>
          :
            ""
        }
        <Grid item xs={6} sm={3} md={3}>
          {
            departures.length > 0 ?
              <FormControl fullWidth>
                <InputLabel id="label-select-departure">Origen</InputLabel>
                <Select
                  labelId="label-select-departure"
                  id="select-departure"
                  value={departure}
                  label="Origen"
                  onChange={(event) => setDeparture(event.target.value)}
                >
                  {
                    departures.map((departure) => {
                      return <MenuItem value={departure.value} key={departure.value}>{departure.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              :
              ""
          }
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          {
            arrivals.length > 0 ?
              <FormControl fullWidth>
                <InputLabel id="label-select-arrival">Destino</InputLabel>
                <Select
                  labelId="label-select-arrival"
                  id="select-arrival"
                  value={arrival}
                  label="Destino"
                  onChange={(event) => setArrival(event.target.value)}
                >
                  {
                    arrivals.map((arrival) => {
                      return <MenuItem value={arrival.value} key={arrival.value}>{arrival.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              :
              ""
          }
        </Grid>

        {
          Object.keys(service).length > 0 &&
            <Grid item sm={12} md={12}>
              Servicio {service.service} con salida de {service.departure} a las {service.departure_time} y llegada a
              las {service.arrival_time}.
              Paradas en {service.stops}
            </Grid>
        }
        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant={"contained"}
            onClick={show_chart}
          >
            Mostrar gráfica
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{
          marginTop: "2em"
        }}>
          {
            (Object.keys(chartOption).length > 0 || chartLoading) &&
              <ReactECharts
                option={chartOption}
                style={{
                  height: "600px"
                }}
                showLoading={chartLoading}
              />
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{
          marginTop: "2em"
        }}>
          {
            (Object.keys(stats).length > 0) &&
            <span>Stats: {JSON.stringify(stats)}</span>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PriceSeat;
